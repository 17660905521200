.service-modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Enable scrolling within the modal overlay */
}

.service-modal {
  background: linear-gradient(135deg, #747779, #5e9b6b);
  padding: 20px;
  width: 80%;
  max-height: 80%; /* Set a max height to make the content scrollable if it exceeds the viewport height */
  margin: auto;
  border-radius: 8px;
  position: absolute;
  overflow-y: auto; /* Enable vertical scrolling for the modal */
}

.button_close  {
  width: 100%;
  display: flex;
  justify-content: flex-end;
 /* margin-left: 0; */
}

.modalll {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
  overflow-y: auto;
}
.button_close{
  left: 0;
}

.ModalPage {
  width: 100%;
  height: 100%;
}

.homepage__services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.homepage__service {
  flex: 1 1 calc(33.333% - 20px);
  box-sizing: border-box;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.homepage__service h3 {
  margin: 0 0 10px;
  font-size: 1.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.homepage__service p {
  margin: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .homepage__service {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .homepage__service {
    flex: 1 1 100%;
  }
}

.partners-page {
  background: linear-gradient(135deg, #3a3a3a, #1a1a1a); /* Initial gradient */

  color: #ffffff;

    margin: 0 auto;
    padding: 20px;
    height: 100vh;
}
.partners-page>h1 {
    margin: 20px auto;
    text-align: center;
    color: #666;
}

.partners-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust minmax values as needed */
    gap: 20px;
    background: linear-gradient(135deg, #747779, #5e9b6b);
}

.partner-card {
    padding: 20px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    transition: all transform 0.3s ease-in-out;
    margin: 0 auto;
    cursor: pointer;
    background: linear-gradient(135deg, #747779, #5e9b6b); /* Harmonized colors */
    width: 40%;
    box-shadow: 0 4px 12px rgba(185, 172, 172, 0.3); /* Stronger 3D effect */
    color: #fff;


}

.partner-card:hover {
    transform: translateY(-5px);
}

.partner-card img {
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.partner-card:hover img {
    transform: scale(1.1);
}

.partner-details {
    padding: 10px 0;
    text-align: center;
}

.partner-details h3 {
    margin-top: 10px;
    font-size: 1.5rem;
    color: #333;
}

.partner-details p {
    color: #666;
    line-height: 1.4;
}

.large-partner {
    grid-column: span 2; /* Make this partner card span two columns */
    height: 300px; /* Adjust height as needed */
}

.large-partner img {
    height: 100%; /* Ensure image fills the card */
    object-fit: cover; /* Maintain aspect ratio */
}


@keyframes expand-service {
    from {
      opacity: 0;
      max-height: 0;
    }
    to {
      opacity: 1;
      max-height: 1000px; /* Adjust based on your content */
    }
  }

.modal {
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
}

.modal-content {
    background: linear-gradient(135deg, #747779, #5e9b6b); /* Harmonized colors */
    margin: 20% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 8px;
    position: relative;
}

.modal-content h2 {
    text-align: center;
    color: #333;
}

.modal-content p {
    color: #666;
    line-height: 1.4;
    text-align: center;
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: #aaa;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Contact.css */
.ContactStyleContainer {
    min-height: 100vh;
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: Arial, sans-serif;
}

.ContactStyleContainer div {
    margin-bottom: 20px;
}

.ContactStyleContainer div:last-child {
    margin-bottom: 0;
}

.ContactStyleContainer p {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.ContactStyleContainer div > div {
    margin-bottom: 10px;
}

.ContactStyleContainer div > div p {
    font-weight: bold;
    color: #555;
}

.ContactStyleContainer div > div p:first-child {
    margin-bottom: 5px;
    font-weight: normal;
}

.ContactStyleContainer h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #444;
}

@media (max-width: 768px) {
    .ContactStyleContainer {
        padding: 15px;
    }

    .ContactStyleContainer div {
        margin-bottom: 15px;
    }
}

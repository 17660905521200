.body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .coming-soon-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #1e3c72, #2a5298);
    color: white;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .coming-soon-content {
    z-index: 1;
    animation: fadeIn 2s ease-in-out;
  }
  
  .animated-title {
    font-size: 3rem;
    margin: 20px 0;
    animation: bounce 2s infinite;
  }
  
  .pulsate-button {
    background: #ff6347;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    animation: pulsate 1.5s infinite;
    border-radius: 5px;
  }
  
  .particles {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    background-size:contain;
    /* background: url('https://via.placeholder.com/800x600') repeat; */
    opacity: 0.3;
    z-index: 0;
    animation: moveParticles 30s linear infinite;
  }
  
  .logo-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo-spin img {
    width: 150px;
    height: 150px;
    animation: spin 10s linear infinite;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes moveParticles {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 800px 600px;
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
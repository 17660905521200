@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
  font-family: "Poppins", sans-serif;
}

#login-form {
  width: 400px;
  max-width: 100%;
  margin: 50px auto;
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

#login-form h1 {
  text-align: center;
  margin: 0;
  padding: 20px 0;
  font-size: 28px;
  font-weight: bold;
  color: #333;
  animation: slideIn 1s ease-in-out;
}

#login-form form {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

#login-form form label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #666;
}

#login-form form input[type="email"],
#login-form form input[type="password"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
}

#login-form form input[type="email"]:focus,
#login-form form input[type="password"]:focus {
  border-color: dodgerblue;
}

#login-form form input[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: dodgerblue;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

#login-form form input[type="submit"]:hover {
  background-color: deepskyblue;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.error-message {
  color: #ff6347;
  font-size: 14px;
  margin-top: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply CSS reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure full viewport height */
html, body, #root {
  height: 100%;
}

/* Ensure no overflow and full width */
body {
  overflow-x: hidden; /* Prevent horizontal scroll */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Adjust font-family as needed */
  background-color: #1a1a1a; /* Set background color for the entire page */
  color: #ffffff; /* Set default text color */
}
*::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

* {
  -ms-overflow-style: none;  /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
}

